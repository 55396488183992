import { useContext } from "react";
import { styled } from "styled-components";
import { context } from "../context/context";
import { FeatureBtn } from "../styled/input/buttons/Buttons";
import { formatter } from "../utils/utils";

const AirdropBalance = ({ style }) => {
  const { airdropBalance } = useContext(context);

  return (
    <AirdropBalanceStandard $style={style}>
      <FeatureBtn>
        <img src="/assets/misc/airdrop.svg" alt="progress" />
      </FeatureBtn>

      <div style={{ display: "grid", gap: "16px" }}>
        <span className="titles">
          <p>Free Airdrop Balance</p>
          <p>{formatter.format(airdropBalance)}</p>
        </span>
      </div>
    </AirdropBalanceStandard>
  );
};

const AirdropBalanceStandard = styled.div`
  grid-area: airdrop;
  display: grid;
  grid-template-columns: 60px auto;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 24px;
  background-color: ${(props) =>
    props.$style !== "variant" ? "#151823" : "transparent"};
  padding: ${(props) => (props.$style !== "variant" ? "24px" : "0px")};
  border-radius: 12px;

  .titles {
    display: grid;
    gap: 6px;
    /* justify-content: space-between; */
  }

  .titles p:nth-child(1) {
    color: #bac2de;
    font-weight: 500;
    font-size: 16px;
  }

  .titles p:nth-child(2) {
    color: #fff;
    font-weight: 600;
    font-size: 18px;
  }

  .bars {
    position: relative;
  }

  .bar {
    background-color: #bed3fe;
    height: 4px;
    width: 100%;
    border-radius: 32px;
  }

  .progress {
    background-color: #0c6cf2;
    height: 4px;
    /* width: 60%; */
    width: ${(props) => props.$percent};
    border-radius: 32px;
    position: absolute;
    top: 0;
    transition: all 0.3s ease-in-out;
  }
`;

export default AirdropBalance;
